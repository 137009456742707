<template>
    <div class="task_wrapper">
        <div class="task_full_body_wrapper">
            <taskList
                :taskType="taskType" 
                showPageTitle
                :page_name="pageName" 
                :pageConfig="pageConfig">
                <template>
                    <PageFilter 
                        model="tasks.TaskModel"
                        :key="pageName"
                        size="large"
                        :page_name="pageName" />
                </template>
            </taskList>
        </div>
        <LongPressHelp />
    </div>
</template>

<script>
import PageFilter from '@/components/PageFilter'
import pageMeta from '@/mixins/pageMeta'
import LongPressHelp from '@/components/LongPressHelp.vue'
import { mapState } from 'vuex'

export default {
    mixins: [pageMeta],
    components: {
        taskList: () => import('@apps/vue2TaskComponent/views/Table/Page.vue'),
        PageFilter,
        LongPressHelp
    },
    computed: {
        ...mapState({
            viewType: state => state.task.mobileViewType
        }),
        getRouteInfo() {
            return this.$store.getters['navigation/getRouteInfo'](this.$route.name)
        },
        pageName() {
            if(this.viewType[this.taskType] === 'kanban')
                return `page_kanban_${this.taskType}_tasks.TaskModel`
            return `page_list_${this.taskType}_task.TaskModel`
        },
        taskType() {
            return this.getRouteInfo?.task_type || 'task'
        },
        pageConfig() {
            return this.$route.meta?.pageConfig ? this.$route.meta.pageConfig : null
        },
    },
}
</script>

<style lang="scss" scoped>
.task_full_body_wrapper{
    height: 100%;
}
.task_wrapper {
    overflow: inherit;
}
</style>